<template>
  <v-app>
    <AppBar/>
    <!-- <NavMenu/> -->
    <v-main>
      <router-view/>
    </v-main>
    <AppFooter/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
     AppBar: () => import('@/components/AppBar.vue'),
     AppFooter: () => import('@/components/AppFooter.vue')
     // NavMenu: () => import('@/components/NavMenu.vue'),
  },
  data: () => ({
    //
  }),
};
</script>

<style>
  *{
    --eastman-blue-green: #00867D;
    --eastman-azure: #00729A;
    --eastman-azure-transparent: #00729AB0;
    --eastman-muted-blue: #80A1B6;
    --eastman-muted-green: #9AC2B9;
    --eastman-teal: #69C8C6;
    --eastman-blue: #02BCE4;
    --eastman-blue-transparent: #02BCE41A;
    --unnamed-color-000000: #000000;
    --eastman-gold: #EFAA1F;
    --eastman-blue-washed: #E3F8FC; 
  }

  /* body {
    font-family: Roboto;
  } */
  .azure-text {
    color: var(--eastman-azure);
  }

  .azure-bg {
    background-color: var(--eastman-azure);
  }

  .blue-text {
    color: var(--eastman-blue);
  }

  .blue-bg {
    background-color: var(--eastman-blue);
  }

  .landing-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    padding-left: 15vw;
    padding-bottom: 3em;
    padding-top: 3em;
    min-height: calc(100vh - 128px);
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--eastman-blue-transparent);
  }

  .app-link {
    font: regular normal normal 16px Roboto;
    text-decoration: none !important;
    user-select: none;
    cursor: pointer;
  }

  .app-link-dark {
    color: var(--eastman-azure) !important;
  }
  
  .app-link-dark:hover {
    color: var(--eastman-azure-transparent) !important;
    padding-bottom: 3px;
    border-bottom: solid 3px;
  }

  .app-link-dark-active {
    padding-bottom: 3px;
    border-bottom: solid 3px;
  }

  .app-link-white {
    color: #ffffff !important;
  }

  .app-link-white:hover {
    color: #ffffffB0 !important;
  }

  .title-break {
    word-break: break-word !important;
  }
  /* .v-application {
    font-family: Roboto;
  } */
</style>
