import config from './config'
import Vue from 'vue'

export default {
  getResourceToken: async function () {
    const tokenRequest = {
      scopes: config.resourceScopes,
    }

    try {
      let token = localStorage.getItem('ResourceToken')

      if (token === null || token === 'null') {
        token = await Vue.prototype.$msal.acquireToken(tokenRequest)

        if (token.accessToken) {
          localStorage.setItem('ResourceToken', token.accessToken)
        }
        return token.accessToken
      }
      return token
    } catch (e) {
      return null
    }
  }
}