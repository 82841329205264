import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/landing.vue'),
    meta: {
      requireAuth: false,
      guest: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      requireAuth: false,
      guest: true,
    },
  },
  {
    path: '/register/complete',
    name: 'Request Sent',
    component: () => import ('@/views/Complete.vue'),
    meta: {
      requireAuth: false,
      guest: true,
    },
    props: {
      source: 'register' 
    },
  },
  {
    path: '/coatings/predict',
    name: 'Coatings Prediction',
    component: () => import('@/views/coatings/Predict.vue'),
    meta: {
      requireAuth: true,
      guest: false,
    },
  },
  {
    path: '/coatings/results',
    name: 'Prediction Results',
    component: () => import('@/views/coatings/Results.vue'),
    props(route) {
      if (route.query) {
        return {
          gen: route.query.gen === 'true',
          a: parseFloat(route.query.a) || undefined,
          b: parseFloat(route.query.b) || undefined,
          c: parseFloat(route.query.c) || undefined,
          flex: parseFloat(route.query.flex) || undefined,
          fullSet: route.query.fullSet === 'true',
          numRecs: parseInt(route.query.numRecs) || 1,
        }
      }
      return { gen: false }
    },
    meta: {
      requireAuth: true,
      guest: false,
    },
  },
  {
    path: '/contact',
    name: 'Contacut Us',
    component: () => import ('@/views/ContactUs.vue'),
    meta: {
      requireAuth: true,
      guest: false,
    },
  },
  {
    path: '/contact/complete',
    name: 'Contact Sent',
    component: () => import ('@/views/Complete.vue'),
    meta: {
      requireAuth: true,
      guest: false,
    },
    props: {
      source: 'contact' 
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import ('@/views/Feedback.vue'),
    meta: {
      requireAuth: true,
      guest: false,
    },
  },
  {
    path: '/feedback/complete',
    name: 'Feedback Sent',
    component: () => import ('@/views/Complete.vue'),
    meta: {
      requireAuth: true,
      guest: false,
    },
    props: {
      source: 'feedback' 
    },
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import ('@/views/SignIn.vue'),
    meta: {
      requireAuth: false,
      guest: true,
    },
  },
  // {
  //   path: '/about',
  //   name: 'About FastER',
  //   component: () => import ('@/views/About.vue'),
  //   meta: {
  //     requireAuth: false,
  //     guest: false,
  //   }
  // },
  {
    path: '/*',
    name: 'Page Not Found',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      requireAuth: false,
      guest: false,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    // redirect unauthenticated users to register page for authorized pages
    if (!Vue.prototype.$msal.isAuthenticated()) {
      next({
        path: '/register',
      })
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.matched.some(record => record.meta.guest)) {
    // if visiting a guest page (landing / register) and user is logged in, send them to predictions page
    if (Vue.prototype.$msal.isAuthenticated()) {
      next({
        path: '/coatings/predict'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
