import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    currentFormulation: {
      params: null,
      recs: [],
    },
    currentUserInfo: {},
    profileImage: '',
  },
  mutations: {
    toggleDrawer (state) {
      return state.drawer = !state.drawer
    },
    setCurrentParams (state, payload) {
      return state.currentFormulation.params = payload
    },
    setCurrentRecs (state, payload) {
      return state.currentFormulation.recs = payload
    },
    setRecGraph (state, payload) {
      if (state.currentFormulation.recs.length > payload.recNum)
      {
        return state.currentFormulation.recs[payload.recNum].graph = payload.graph
      }
      return state
    },
    setUserInfo (state, payload) {
      return state.currentUserInfo = payload
    },
    setProfileImage (state, payload) {
      return state.profileImage = payload
    },
    setOrgName (state, payload) {
      return state.currentUserInfo.organization = payload
    },
    setCountry (state, payload) {
      return state.currentUserInfo.country = payload
    },
  },
  actions: {
    toggleDrawer (context) {
      context.commit('toggleDrawer')
    },
    setCurrentParams (context, params) {
      context.commit('setCurrentParams', params)
    },
    setCurrentRecs (context, recs) {
      context.commit('setCurrentRecs', recs)
    },
    setRecGraph (context, payload) {
      context.commit ('setRecGraph', payload)
    },
    setUserInfo (context, payload) {
      context.commit ('setUserInfo', payload)
    },
    setProfileImage (context, payload) {
      context.commit('setProfileImage', payload)
    },
    setOrgName (context, payload) {
      context.commit('setOrgName', payload)
    },
    setCountry (context, payload) {
      context.commit('setCountry', payload)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
})
