import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import msal from 'vue-msal'
import Vuelidate from 'vuelidate'
import config from '@/authentication/config'
import VueApexCharts from 'vue-apexcharts'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import resToken from '@/authentication/resourceToken'

Vue.config.productionTip = false

//auth
Vue.use(msal, {
  auth: {
    clientId: config.clientId,
    tenantId: config.tenant,
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_LOGOUT_URL,
    requireAuthOnInitialize: false,
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      profile: '/me',
      org: '/organization',
      // photo: { url: '/me/photo/$value', responseType: 'arraybuffer'},
    },
    onResponse: async function (ctx, response) {
      // console.log(response)
      // const base64string = btoa(String.fromCharCode.apply(null, new Uint8Array(response.photo)))
      store.dispatch('setUserInfo', response.profile)
      // store.dispatch('setProfileImage', base64string)
      store.dispatch('setOrgName', response.org.value[0].displayName)
      store.dispatch('setCountry', response.org.value[0].country ?? response.org.value[0].countryLetterCode)
      try {
        var photo = await Vue.prototype.$msal.msGraph({ url: '/me/photo/$value', responseType: 'arraybuffer', force: true})
        var b64img = btoa(String.fromCharCode.apply(null, new Uint8Array(photo.body)))
        store.dispatch('setProfileImage', b64img)
      } catch (Error) {
        console.log('unable to retrieve user photo')
      }
      //localStorage.setItem('ProfileImage', base64string)
      //localStorage.setItem('CurrentUserInfo', JSON.stringify(response.profile))
    },
  },
  request: {
    scopes: config.scopes,
  },
  cache: {
    cacheLocation: config.cacheLocation,
    storeAuthStateInCookie: false,
  },
  framework: {
    globalMixin: true,
  },
})

//input validation
Vue.use(Vuelidate)

//apex charts for graph display
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const setToken = async (request) => {
  const accessToken = await resToken.getResourceToken()
  // console.log(accessToken)
  if (accessToken != null) {
    // console.log('setting token')
    request.headers = {
      Authorization: `Bearer ${accessToken}`
    }
  }
}

//axios for api requests
axios.defaults.baseURL = process.env.VUE_APP_PREDICTION_API_BASE
axios.interceptors.request.use(
  async request => {
    // apply access token to request to access api
    if (request.url.includes(process.env.VUE_APP_ACCESS_API_BASE)) {
      await setToken(request)
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response !== undefined) {
      const originalRequest = error.config
      // retry a failed request once before giving up. refresh the access token if it was to the access api
      if (error.response.status === 401
        && !originalRequest._retry) {
          originalRequest._retry = true
          // try refreshing the token if the error was from the access api
          localStorage.removeItem('ResourceToken')
          await setToken(originalRequest)
          return axios(originalRequest)
      } else {
        return Promise.reject(error)
      }
    }
  }
)

// ReCaptcha
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA,
  loaderOptions: {
    useRecaptchaNet: true,
  },
})

//vue
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
